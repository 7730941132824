:root {
    --color-primary: #F6911E;
    --color-secondary: #727272;
    --color-bg: #EDF3FF;
    /* --font-primary: 'Helvetica Neue', sans-serif; */
    --font-primary: 'Open Sans', sans-serif;
    --font-secondary: 'Open Sans', sans-serif;
}

/* General */

body {
    overflow-x: hidden;
    font-family: var(--font-primary);
}

section {
    padding-top: 40px;
    padding-bottom: 40px;
}

a {
    color: var(--color-primary);
    text-decoration: none;
}

a:hover {
    color: var(--color-secondary);
}

p {
    font-size: 16px;
    opacity: 0.8;
}

h2 {
    color: var(--color-secondary);
    font-size: 32px;
    font-weight: bold;
}

h2 span {
    color: var(--color-primary);
}

h2::before {
    content: url(../images/icon.png);
    position: relative;
    /* z-index: 100000; */
    top: 10px;
    padding-right: 10px;
}

h2.tq::before, .pri-policy h2::before {
    all: unset;
}

h3 {
    font-size: 20px;
    font-weight: bold;
}

h3.sub {
    margin-top: 15px;
    margin-bottom: 50px;
    font-size: 16px;
    font-weight: normal;
}

.content {
    margin-top: 45px;
    margin-bottom: 30px;
}

.link-primary {
    font-size: 16px;
    font-weight: bold;
    color: var(--color-primary);
}

.link-primary:hover {
    color: var(--color-secondary);
}

.section-bg {
    background-color: #F9F9FD;
}

.entry {
    padding-top: 80px;
}

.cmt-1 {
    margin-top: -52px;
}

.pb-unset {
    padding-bottom: unset;
}

.btn-primary {
    margin-top: 25px;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    border-radius: 7px;
    background-color: #0064B1;
    border-color: #0064B1;
    color: #fff;
}

.btn-secondary {
    font-size: 14px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: capitalize;
    border-radius: 7px;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    color: #131D3B;
}

.btn-primary:hover {
    background-color: #0064B1;
    border-color: #0064B1;
    box-shadow: 0px 10px 30px #1264B14B;
}

.mt-unset {
    margin-top: unset !important;
}

.fa-flip-horizontal {
    /* -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)"; */
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.MuiTypography-root {
    color: #000;
    font-weight: 500 !important;
}

.header {
    background-color: var(--color-primary) !important;
}

.menuicon {
    color: var(--color-primary) !important;
}

/* Header */

#header {
    position: relative;
    z-index: 9999999;
}

.MuiListItemIcon-root {
    min-width: 42px !important;
}

.MuiTypography-root {
    color: #000;
}

#header .container {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

/* Navbar */

#navbar ul {
    justify-content: flex-end;
}

.navbar ul {
    margin-top: 20px;
    display: flex;
    list-style: none;
    align-items: center;
    padding-left: 0;
}

.navbar ul li {
    padding: 10px 0 10px 40px;
}

.navbar ul li:first-child {
    padding-left: 0;
}

.navbar ul li a {
    color: #000;
    font-size: 18px;
    opacity: .8;
}

.navbar ul li.active a {
    color: var(--color-primary);
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--color-secondary);
}

.breadcrumbs {
    text-align: center;
    padding: 96px 0;
    min-height: 100%;
    background: linear-gradient(130deg,
            rgba(253, 154, 14, 0.7) 0%,
            rgba(168, 125, 60, 0.7) 30%,
            rgba(83, 78, 71, 0.7) 100%),
        url(../images/bg-breadcumbs.png);
    background-size: cover;
}

.breadcrumbs h1 {
    font-size: 40px;
    font-weight: bold;
    color: #F9F9FD;
}

.breadcrumbs ul {
    padding-left: unset;
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
}

.breadcrumbs ul li a, .breadcrumbs ul li+li {
    font-size: 14px;
    font-weight: 600;
    color: #F9F9FD;
}

.breadcrumbs ul li a:hover {
    color: var(--color-primary) !important;
}

.breadcrumbs ul li+li {
    padding-left: 10px;
}

.breadcrumbs ul li+li::before {
    padding-right: 10px;
    content: "/";
    line-height: 27px;
}

.slider h1, .slider h2 {
    font-size: 60px;
    font-weight: 700;
}

.slider .carousel-caption p {
    font-size: 36px;
    font-weight: 600;
}

.slider .carousel-caption {
    top: 25%;
    text-align: center;
    color: #fff;
    border-radius: 50%;
}


.slider .carousel-caption svg {
    margin-top: -1px;
}

.btn-primary {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.btn-primary:hover {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
}

.bg-1 {
    background-image: url(../images/bg-1.svg);
    background-position: bottom right;
    background-repeat: no-repeat;
    /* padding-bottom: unset; */
}

.bg-2 {
    padding: unset;
    background-image: url(../images/bg-2.png);
    background-position: top center;
    background-repeat: no-repeat;
}

.bgc-2 {
    background: transparent linear-gradient(360deg, #444343d7 0%, #bebdbbd3 100%) 0% 0% no-repeat padding-box;
}

.bgc-2 .container {
    padding-top: 60px;
    padding-bottom: 60px;
}

.touch h2 {
    font-size: 35px;
    font-weight: 700;
    color: #fff;
}

.touch h2::before {
    all: unset;
}

.benefits {
    margin-top: 25px;
    padding: 22px;
    background-color: #FFFFFF;
    box-shadow: 0px 10px 30px #7B8ECB29;
}

marquee {
    margin-bottom: -4px;
}

.benefits img {
    float: left;
    border-radius: 50%;
    padding: 12px;
    background-color: #F9F9FD;
}

.benefits h4 {
    margin-left: 90px;
    font-size: 18px;
    font-weight: 600;
    color: #131D3B;
    text-transform: capitalize;
    opacity: 0.8;
}

.benefits p {
    margin-left: 90px;
    line-height: 30px;
    font-size: 16px;
    font-weight: 400;
    color: #131D3B;
    opacity: 0.8;
}

.benefits a {
    margin-left: 90px;
    font-size: 16px;
    font-weight: 500;
    color: #1264B1;
}

.team {
    text-align: center;
    position: relative;
}

.team img.design {
    position: absolute;
    right: 80px;
}

.team img.profile {
    border-radius: 50%;
    margin-bottom: 25px;
}

.team h4 {
    font-size: 20px;
    font-weight: 600;
    color: #131D3B;
    opacity: 0.8;
}

.team p {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #131D3B;
    opacity: 0.8;
}

.des-2 {
    top: -50px;
    left: -75px;
    position: absolute;
}

#footer {
    padding-top: 54px;
    padding-bottom: 70px;
    background: url('../images/bg-3.svg') no-repeat bottom left, var(--color-bg);
}

.bottom {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: var(--color-secondary);
}

.why-choose h4 {
    color: var(--color-secondary);
    font-weight: 700;
}
#footer hr {
    background-color: #fff;
}

#footer svg {
    color: #FFF;
    float: left;
    margin-right: 5px;
}

#footer .social ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    /* justify-content: center; */
}

#footer .social ul li svg {
    margin: 10px;
    font-size: 40px;
    padding: 8px;
    border-radius: 50%;
    border: 2px solid var(--color-primary);
    color: var(--color-secondary);
}

#footer h5 {
    margin-bottom: 25px;
    font-size: 14px;
    font-weight: 600;
    color: var(--color-secondary);
    text-transform: uppercase;
}

#footer h5::after {
    display: inline-block;
    width: 2rem;
    height: .2rem;
    margin-left: 8px;
    margin-bottom: 4px;
    content: '';
    background-color: var(--color-primary) !important;
    border-radius: 10px;
    border: 1px solid var(--color-primary);
}

#footer .logo {
    margin-top: -4rem;
}

#footer p {
    font-size: 14px;
    font-weight: 400;
    color: var(--color-secondary);
}

#footer .contact p {
    padding-left: 30px;
}

#footer ul {
    list-style: none;
    padding-left: unset;
}

#footer ul li a, #footer a {
    line-height: 25px;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-secondary);
}

#footer ul li a:hover, #footer a:hover {
    color: var(--color-primary);
}

.copyright p, .copyright a {
    background-color:white;
    /* font-size: 16px; */
    padding: 15px 0px 15px 0px !important;
    margin: 0px !important;
}
.copyright {
    background-color: var(--color-bg) !;
}

.bottom {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: var(--color-bg);
}

#footer .links {
    text-align: right;
}

.testimonials svg {
    bottom: 0;
    color: #000;
}

.testimonials svg:hover {
    color: #ffc942;
}

.testimonials .slick-prev, .testimonials .slick-next {
    top: unset;
    bottom: -35px;
}

.testimonials .slick-prev {
    left: 47%;
}

.testimonials .slick-next {
    right: 47%;
}

.testimonial {
    margin: 25px;
    padding: 30px;
    background-color: #FFFFFF;
    box-shadow: 10px 10px 20px #7B8ECB29;
}

.testimonial .slick-slide {
    position: relative;
}

.testimonial svg {
    margin-top: -12%;
    border-radius: 50%;
    z-index: 2;
    background-color: #ffc942;
    color: #fff;
    font-size: 50px;
}

.testimonial svg:hover {
    background-color: #ffc942;
    color: #fff;
}

.testimonial p {
    font-size: 16px;
    line-height: 35px;
    color: #131D3B;
    opacity: 0.8;
}

.testimonial h4 {
    font-size: 18px;
    color: #0064B1;
    text-transform: capitalize;
}

.contact-info {
    text-align: center;
}

.contact-info svg {
    margin: 0 10px 12px 10px;
    color: var(--color-secondary);
}

.contact-info h4 {
    font-size: 18px;
    font-weight: 600;
}

.contact-info .icon {
    margin-top: 40px;
}

.contact-info p, .contact-info p a {
    text-align: unset;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    opacity: 0.8;
    transition: .3s;
}

.contact-info p a:hover {
    color: var(--color-primary);
}

.contact-info .icon:before, .contact-info .icon:after {
    display: inline-block;
    content: "";
    border-top: 1px solid var(--color-secondary);
    width: 48px;
    transform: translateY(-0.6rem);
}

.contact-form {
    padding: 8px 40px 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 30px #53749A29;
}

.contact-form input[type=text], .contact-form input[type=email], .contact-form textarea {
    padding: 0.675rem 0.75rem;
}

.contact-form .react-tel-input .form-control {
    width: 100%;
    height: 45px;
    margin-top: 35px !important;
}


.contact-form h2 {
    margin-top: 14px;
    padding-bottom: 20px;
}

.contact-form .form-control, .contact-form button {
    margin-top: 35px;
}

.contact-form .MuiInputLabel-shrink {
    padding-left: 7px !important;
    padding-right: 7px !important;
    background-color: #FFF !important;
    color: #03abee !important;
}

.contact-form .MuiButton-root {
    font-size: 14px;
    border: none;
    padding: 10px 20px !important;
    background-color: #0064B1;
    border-radius: 6px;
    color: #fff !important;
}

.contact-form .MuiButton-root:hover {
    background-color: transparent;
    color: #0064B1 !important;
    border: 1px solid #0064B1;
}

.machinery {
    margin-bottom: 60px;
}

.slick-slide .machinery img {
    display: unset !important;
}

.machinery .count h4 span {
    font-size: 3rem;
    color: #C7C9CB;
}

.machinery .count h4 {
    font-weight: 600;
    font-size: 1.1rem;
    color: #131D3B;
    opacity: 0.9;
}

.pri-policy {
    margin-top: 50px;
    margin-bottom: 50px;
}

.sitemap ul {
    list-style: none;
    padding-left: unset;
}

.sitemap ul li {
    padding-top: 5px;
    padding-bottom: 5px;
}

.sitemap ul li a {
    color: #000000;
}

.sitemap ul li a:hover {
    color: #FFCC2F;
}

.gallery .MuiTab-root {
    text-transform: none;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    opacity: 0.8;
}

.gallery .MuiTab-root.Mui-selected {
    color: #000000;
}

.gallery .MuiTabs-indicator {
    background-color: #FFCC2F;
}

.gallery p {
    opacity: 1;
}

.gallery img {
    margin-bottom: 25px;
}

.info h4 span {
    font-size: 16rem;
    color: #FFCC2F;
}

.info h4 {
    margin-top: -150px;
    margin-left: -56px;
    font-weight: 700;
    font-size: 1.3rem;
    color: #131D3B;
    opacity: 0.8;
}

.client img {
    filter: grayscale();
    opacity: 0.8;
}

.client:hover img {
    filter: unset;
    opacity: 1;
}

.menu-footer {
    position: fixed;
    font-size: 13px;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: #ffffffd1;
    z-index: 999;
    border-top: 1px solid #ccc;
}

.menu-footer a {
    color: #374957 !important;
}

.menu-footer a.active {
    color: var(--color-primary) !important;
}

.menu-footer .b-right {
    border-right: 1px solid #ccc;
}

.form-control:focus {
    border-color: #dc922740;
    box-shadow: 0 0 0 0.1rem rgb(220 146 39 / 28%);
}

section.map {
    padding-bottom: unset;
}


#about {
    padding-bottom: unset;
}

.btn-check:focus+.btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    box-shadow: 0 0 0 0.25rem var(--color-secondary);
}

.project-single {
    padding: 15px;
}
.team {
    box-shadow: 0px 3px 12px #00000029;
}

.project-single:hover h4 {
    display: block;
}

.project-single h4 {
    display: none;
    position: relative;
    margin-top: -94px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: unset;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
    background-color: #000000b7;
    text-transform: capitalize;
}

#projects {
    padding-bottom: 80px;
}

.projects .slick-dots li.slick-active button:before {
    color: var(--color-secondary);
}

.projects .slick-dots li button:before {
    font-size: 10px;
}

.projects .slick-dots li.slick-active button:before {
    font-size: 15px;
}

.mission-row {
    overflow: hidden;
    position: relative;
}

.card-mission-1, .card-mission-2, .card-mission-3 {
    text-align: center;
    padding: 30px 25px 60px 25px ;
    background: #F9F9FD 0% 0% no-repeat padding-box;
    border-radius: 10px;
    transition: all 0.5s;
    height: 90%;
}
.card-mission-1 p, .card-mission-2 p, .card-mission-3 p {
        text-align: justify;
}

.card-mission-1:hover, .card-mission-2:hover, .card-mission-3:hover {
    padding: 30px 25px 60px 25px ;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 60px #586EB529;
    transform: translateY(-10px);
}

.card-mission-1 .icon-box, .card-mission-2 .icon-box, .card-mission-3 .icon-box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 12px #00000029;
    border-radius: 16px;
    margin-bottom: 30px;
    transition: all 0.5s;
}

.card-mission-1:hover .icon-box, .card-mission-2:hover .icon-box, .card-mission-3:hover .icon-box {
    background: var(--color-secondary) 0% 0% no-repeat padding-box;
    color: var(--color-primary);
    box-shadow: none;
    border-radius: 16px;
}

.mission table {
    margin: auto;
}

.or-1, .or-2, .or-3 {
    display: table;
}

.hover-1, .hover-2, .hover-3 {
    display: none;
}

.card-mission-1:hover .or-1 {
    display: none;
}

.card-mission-1:hover .hover-1 {
    display: block;
}

.card-mission-2:hover .or-2 {
    display: none;
}

.card-mission-2:hover .hover-2 {
    display: block;
}

.card-mission-3:hover .or-3 {
    display: none;
}

.card-mission-3:hover .hover-3 {
    display: block;
}

.counts h5 {
    text-align: center;
}

.counts .card-count {
    width: fit-content;
    padding: 2px 8px 2px 58px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.counts .card-count.type-1 {
    background-color: #f3a954;
}

.counts .card-count.type-2 {
    background-color: #E3DDD6;
}

.counts .card-count span {
    font-size: 60px;
    font-weight: 600;
    margin-right: -44px;
    color: var(--color-secondary);
}

section.counts {
    padding: unset;
}

.img-area {
    display: flex;
    position: relative;
}

.design-2 {
    width: fit-content;
    height: 150px;
    padding: 22px;
    position: relative;
    top: 30%;
    left: 15%;
    background-color: var(--color-primary);
}

.contacts {
    overflow: hidden;
    margin-top: 30px;
    margin-bottom: 30px;
}

.contacts .icon {
    float: left;
    padding: 7px;
    border-radius: 50%;
    color: #ffffff;
    width: fit-content;
    background-color: var(--color-primary);
}

.contacts a, .contacts p {
    font-size: 16px;
    color: #000;
    opacity: .7;
    padding-left: 20px;
}

.contacts p {
    padding-left: 60px;
}

.contacts a:hover {
    color: var(--color-secondary);
}

section#contact {
    padding-bottom: unset;
}



.photos .slick-prev:before, .slick-next:before {
    font-size: 28px;
    color: var(--color-secondary);
}

.photos .slick-prev {
    left: -52px;
}

.photos .slick-next {
    right: -48px;
}

img.bg {
    background-image: url(../images/img-bg.png), url(../images/img-bg.png);
    background-position: left top, right bottom;
    background-repeat: no-repeat, no-repeat;
    padding: 15px;
}

section.rmc {
    background-image: url(../images/bg-4.svg);
    background-position: right bottom;
    background-repeat: no-repeat;
}